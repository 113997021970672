import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { default as _TextField } from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { Typography, Button, Tooltip } from "@material-ui/core";

import { store, addError, fetchImages, fetchCurrentProfile } from "./Store";
import { instanceAPI } from "./Api";

import { styled, withStyles } from "@material-ui/core/styles";

const TextField = styled(_TextField)({
  margin: "1rem",
});

const WarningTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.warning.main,
  }
}))(Tooltip);

export default class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.reset(true);
  }

  reset(hard) {
    let d = {
      label: "",
      owner: "",
      user_owner: "",
      default_email: "",
      admin_password: "",
      disabled: false,
      memo: "",
      db_source: "",
      db_source_instance: "cinnamon-badger",
      autostart: false,
      defaultpassword: "",
      images: [],
      imageselected: "",
      advanced_entry: false,
      memory_limit_mb: "2000"
    };
    d['user_owner'] = d['default_email'];
    if (hard) {
      return d;
    } else {
      this.setState(d);
      return d;
    }
  }

  componentDidMount() {
    store.subscribe(() => this.setState({ images: store.getState().images.slice().sort(),
                    default_email: store.getState().currentProfile.email,
                    user_owner: store.getState().currentProfile.email}));
    store.dispatch(fetchImages());
    store.dispatch(fetchCurrentProfile());
  }

  submitInstance = () => {
    let d = {};
    d["label"] = this.state.label;
    d["owner"] = this.state.owner;
    d["user_owner"] = this.state.user_owner;
    d["admin_password"] = this.state.admin_password;
    d["disabled"] = this.state.disabled;
    d["memo"] = this.state.memo;
    d["db_source"] = this.state.db_source;
    //d['db_source_instance'] = this.state.db_source_instance;
    d["autostart"] = this.state.autostart;
    d["image"] = this.state.imageselected;
    d["memory_limit_mb"] = this.state.memory_limit_mb;
    
    // If the password is not the default, use the listed password
    if (this.state.defaultpassword) {
      d["defaultpassword"] = this.state.defaultpassword;
    }

    // Validation
    if (!d.db_source && !this.state.db_source_instance) {
        store.dispatch(addError("Must specify one of 'Src Environment' or 'Database (advanced)'"));
        return;
    }

    if (!d.label) {
        store.dispatch(addError("Must specify label")); return;
    }
    if (!d.owner) {
        store.dispatch(addError("Must specify owner")); return;
    }
    if (!d.admin_password) {
        store.dispatch(addError("Must specify ACC Admin Password")); return;
    }

    // Find instance with the internal name
    if (!this.state.db_source) {
      let found = false;
      for (var instance of this.props.instances) {
        if (instance.internal_name === this.state.db_source_instance) {
          d["db_source"] = instance.db_dest;
          if (instance.status === 'running') {
            store.dispatch(addError('Cannot copy instance that is running, stop it first'));
            return;
          } else if (instance.status === 'uncreated') {
            store.dispatch(addError('Cannot copy instance that isn\'t created, create it first'));
            return;
          }
          found = true;
          break;
        }
      }
      if (!found) {
        store.dispatch(
          addError(
            `Didn't find instance name with name ${this.state.db_source_instance}`
          )
        );
        return;
      }
    }

    // Remove blank "" entries from dict
    for (let k in d) {
      if (d[k] === "") {
        delete d[k];
      }
    }

    instanceAPI.create(d).catch((e) => {
      store.dispatch(addError(e.message));
    });
    this.reset(false);
  };

  TF = (props) => {
    return (
      <Tooltip title={props.desc}>
      <TextField
        label={props.label}
        value={this.state[props.field]}
        onChange={(event) => {
          let v = {};
          v[props.field] = event.target.value;
          this.setState(v);
        }}
        required={props.required}
        type={props.type}
        {...props.textFieldProps}
      />
      </Tooltip>
    );
  };

  CB = (props) => {
    return (
      <Tooltip title={props.desc}>
      <FormControlLabel
        label={props.label}
        control={
          <Checkbox
            checked={this.state[props.field]}
            onChange={(event) => {
              let v = {};
              v[props.field] = event.target.checked;
              this.setState(v);
            }}
          />
        }
      />
      </Tooltip>
    );
  };
  render() {
    return (
      <Container maxWidth="lg">
        <Typography variant="h2">Create a new Instance</Typography>
        <form>
          <this.CB label="Expert" field="advanced_entry" 
            desc="Allows for extra fields" />
          <br />
          <this.TF label="Label" field="label" required 
            desc="Friendly Name"/>
          <this.TF label="Owner" field="owner" required
            desc="Owners friendly name" />
          <this.TF label="Owner Email" field="user_owner" required
            desc="Owners email address"/>
          <this.TF
            label="ACC Admin Password"
            type="password"
            field="admin_password"
            required
            desc='Password used for the "Admin" operator in Adobe Campaign Classic'
          />
          <this.TF label="Description" field="memo"
            desc="A field for extra data the owner wishes to include." />
          {this.state.advanced_entry &&
            <this.TF label="Src Environment" field="db_source_instance" 
              desc={"Internal name of the source environment." 
              + " Do not use if you are using Database (advanced) field"} required />
          }

          
          {this.state.advanced_entry &&
            <Tooltip title="Which version of the image to use, default is 9032.11, Gold Standard 11" placement="top"> 
              <WarningTooltip disableHoverListener 
                title="This must match the Src Environment build version" placement="right"> 
                <FormControl style={{margin: '1rem'}}>
                  <InputLabel htmlFor="image">Image</InputLabel>
                  <NativeSelect
                    value={this.state.imageselected}
                    onChange={(event) => {
                      this.setState({'imageselected': event.target.value});
                    }}
                    inputProps={{
                      name: 'image',
                      id: 'image',
                    }}
                  >
                    <option aria-label="None" value={undefined} />
                    {this.state.images.map(i => <option key={i}
                      value={i}>{i}</option>)}
                  </NativeSelect>
                </FormControl>
              </WarningTooltip>
            </Tooltip>
          }

          {this.state.advanced_entry &&
            <this.TF label="Src Env Admin Password" field="defaultpassword"
              type="password"
              desc={"Source environment admin operator password, used for "
              + "changing the password on first start. If unknown leave blank."}/>
          }
          {this.state.advanced_entry &&
            <>
            <this.TF label="Database" field="db_source"
              desc={"Specifies the database name to clone, don't use with "
              + "Src Env Admin Password"}/>
            <this.TF label="Memory Limit (MB)" field="memory_limit_mb"
              desc={"Memory limit in MB for the container"}/>
            </>
          }

          {this.state.advanced_entry &&
            <this.CB label="Auto Start" field="autostart" 
              desc="Auto start with host operating system" />
          }
          {this.state.advanced_entry &&
            <this.CB label="Disabled" field="disabled" 
              desc="Disable all actions on the instance" />
          }
          <div>
            <Button onClick={this.submitInstance} variant="contained">
              Submit
            </Button>
          </div>
        </form>
      </Container>
    );
  }
}
