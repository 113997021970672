import React from "react";
import { default as _Button } from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import { styled } from "@material-ui/core/styles";

import { store, addError } from "./Store";
import { instanceAPI } from "./Api";

function inArray(arr, element) {
  return arr.indexOf(element) !== -1;
}

const Button = styled(_Button)({
  margin: "0.5rem",
});

export class InstanceActionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  onClick = (option) => {
    return (eventv) => {
      let instance = this.props.instance.id;
      let action = option;
      if (action === 'delete' && 'protected' in this.props.instance 
        && this.props.instance.protected) {
        store.dispatch(addError('Cannot delete protected instance'));
      } else {
        instanceAPI.control(instance, action).catch((e) => {
          store.dispatch(addError(e.message));
        });
      }
      this.handleClose(null);
    };
  };

  handleClose = (eventv) => {
    this.setState({ open: false });
    this.props.dialogControl(false);
  };

  render() {
    let options = this.props.instance.status;
    switch (options) {
      case "uncreated":
        options = ["create", "delete"];
        break;
      case "created":
        options = ["start", "delete"];
        break;
      case "running":
        options = ["stop"];
        break;
      case "exited":
        options = ["delete", "start"];
        break;
      default:
        options = ["unknown"];
        break;
    }
    return (
      <Dialog onClose={this.handleClose} open={this.state.open}>
        <Box p={1}>
          <Typography variant="h2">Instance Actions</Typography>
          <Typography variant="subtitle2">Instance:</Typography>
          <Typography variant="subtitle1">
            {this.props.instance.label}
          </Typography>
          {inArray(options, "start") && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.onClick("start")}
            >
              Start
            </Button>
          )}
          {inArray(options, "create") && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.onClick("create")}
            >
              Create
            </Button>
          )}
          {inArray(options, "delete") && (
            <Button
              variant="contained"
              color="secondary"
              onClick={this.onClick("delete")}
            >
              Delete
            </Button>
          )}
          {inArray(options, "stop") && (
            <Button
              variant="contained"
              color="secondary"
              onClick={this.onClick("stop")}
            >
              Stop
            </Button>
          )}
          {inArray(options, "unknown") && (
            <Button disabled>Unknown State</Button>
          )}
        </Box>
      </Dialog>
    );
  }
}
