import merge from "deepmerge";
import {
  createAsyncThunk,
  createSlice,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";

import { instanceAPI, userAPI } from "./Api";

const copy = (x) => merge(x, {});

const updateInstance = createAction("instance/update", function update(
  instance
) {
  return { instance: instance };
});

const fetchInstances = createAsyncThunk(
  "instance/fetchall",
  async (arg, thunkAPI) => {
    try {
      const response = await instanceAPI.fetchAll();
      return response;
    } catch (e) {
      store.dispatch(addError(e.message));
    }
  }
);

const fetchUsers = createAsyncThunk(
  "user/fetchall",
  async (arg, thunkAPI) => {
    try {
      const response = await userAPI.fetchAll();
      return response;
    } catch (e) {
      store.dispatch(addError(e.message));
    }
  }
);


const fetchCurrentProfile = createAsyncThunk(
  "user/currentProfile",
  async (arg, thunkAPI) => {
    try {
      const response = await userAPI.fetchCurrentProfile();
      return response;
    } catch (e) {
      store.dispatch(addError(e.message));
    }
  }
);

const fetchImages = createAsyncThunk(
  "images/fetchall",
  async (arg, thunkAPI) => {
    try {
      const response = await instanceAPI.fetchImages();
      return response;
    } catch (e) {
      store.dispatch(addError(e.message));
    }
  }
);

const instanceSlice = createSlice({
  name: "instance",
  initialState: { instances: [], errors: [], users: [], images: [], currentProfile: {email: ""}},
  reducers: {
    [updateInstance]: (state, action) => merge(state.instances, action),
    addError: (state, action) => {
      let s = copy(state);
      s.errors.push(action.payload);
      return s;
    },
    clearError: (state, action) => {
      let s = copy(state);
      s.errors = [];
      return s;
    }
  },
  extraReducers: {
    [fetchInstances.fulfilled]: (state, action) => {
      state.instances = action.payload;
    },
    [fetchCurrentProfile.fulfilled]: (state, action) => {
      if (action.payload) {
        state.currentProfile = action.payload;
      }
    },
    [fetchUsers.fulfilled]: (state, action) => {
      if (action.payload && 'users' in action.payload) {
        state.users = action.payload.users;
      }
    },
    [fetchImages.fulfilled]: (state, action) => {
      if (action.payload && 'images' in action.payload) {
        state.images = action.payload.images;
      }
    }
  },
});

const store = configureStore({
  reducer: instanceSlice.reducer,
});

const { actions } = instanceSlice;
const { addError, clearError } = actions;

export { fetchImages, fetchInstances, fetchUsers, fetchCurrentProfile,
  instanceSlice, store, addError, clearError };
