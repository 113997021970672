import React from "react";
import { Typography, Grid, LinearProgress} from "@material-ui/core";

import { store, addError} from "./Store";
import { ENDPOINT } from "./Config";

export default class MemoryWidget extends React.Component {

constructor(props) {
  super(props);
  this.state = {'memory': {}};
}

request() {
  fetch(ENDPOINT + '/system/memory'
    ).then(d => d.json()).then(j => this.setState({memory: j})
    ).catch(e => store.dispatch(addError("Failed to request memory statisics"))); 
}

requestStart = () => {

  this.request();
  this.toid = setTimeout(this.requestStart, 2000);
}

componentDidMount() { 
  this.requestStart();
}

componentWillUnmount() {
  if (this.toid) {
    clearTimeout(this.toid);
  }
}

render() {
  let mempercent = 50;
  let mbavail = 0;
  let mbtotal = 0;
  let mbused = 0;
  if (this.state.memory.localhost) {
    let mem = this.state.memory.localhost;
    mbavail = Math.round(mem.available / 1024);
    mbtotal = Math.round(mem.total / 1024);
    mbused = mbtotal - mbavail;
    mempercent = Math.ceil((mbused / mbtotal) * 100);
  }
    
  return (
    <>
      <Typography variant="subtitle1">Local memory</Typography>
      <LinearProgress variant="determinate" value={mempercent} />
      <Typography variant="body2">Used: {mbused} MB</Typography>
      <Typography variant="body2">Available: {mbavail} MB</Typography>
      <Typography variant="body2">Total: {mbtotal} MB</Typography>
    </>
);
}

}
