import React from "react";
import { default as _Button } from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import {Grid, FormControl, InputLabel, Select, TextField as _TextField} from "@material-ui/core";

import { styled } from "@material-ui/core/styles";

import { store, addError, fetchUsers } from "./Store";
import { userAPI } from "./Api";

const Button = styled(_Button)({
  margin: "0.5rem",
});
const TextField = styled(_TextField)({
  marginTop: "0.5rem",
});

function findUserInArray(arr, userid) {
    for (let searchuser of arr) {
      if (searchuser.id === userid) {
        return searchuser;
      }
    }
    return {};
}

export class AdminWindowDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      users: [],
      role: 'user',
      user: null
    };
  }

  componentDidMount() {
    store.subscribe(() => this.setState({ users: store.getState().users }));
    store.dispatch(fetchUsers());
  }

  onClick = add => {
    return event => {
      userAPI.update(this.state.user, this.state.role, add).catch((e) => {
        store.dispatch(addError(e.message))});
      store.dispatch(fetchUsers());
      //this.setState({ open: false });
      //this.props.dialogcontrol(false);
    }
  };

  handleChange = (eventv) => {
    let id = eventv.target.value;
    this.setState({ user: parseInt(id)  });
  }

  handleClose = (eventv) => {
    this.setState({ open: false });
    this.props.dialogcontrol(false);
  };

  render() {
    let selectuser = findUserInArray(this.state.users, this.state.user);
    let roles = 'roles' in selectuser ? selectuser.roles : [];
    let rolestring = roles.reduce((acc, val) => acc + val.name + "\n", "");

    return (
      <Dialog onClose={this.handleClose} open={this.state.open}>
           <form>
           <Box m={2}>
          <Grid container direction="column">
             <FormControl>
                <InputLabel htmlFor="native-user">User</InputLabel>
                <Select
                  native
                  value={this.state.user}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'user',
                    id: 'native-user',
                  }}
                >
                  <option aria-label="None" value="" />
                  {this.state.users.map(u => <option 
                    key={u.id} value={u.id}>{u.email}</option>)}
                </Select>
              </FormControl> 
              <TextField label="Group" value={this.state.role}
                 onChange={(e) => {
                this.setState({role: e.target.value})}}/>
              <TextField label="Existing roles" multiline
                rows={4} InputProps={{readOnly: true}}
                value={rolestring}/>
              
              <Button color="primary"
                  onClick={this.onClick(true)}>Add</Button>
              <Button color="secondary"
              
                  onClick={this.onClick(false)}>Remove</Button>
              <Button onClick={this.handleClose}>Exit</Button>
        </Grid>
        </Box>
          </form>

      </Dialog>
    );
  }
}
