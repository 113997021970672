import React from "react";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { clearError, store } from "./Store";

export default class MessageCentre extends React.Component {

  clear = () => {
    store.dispatch(clearError());  
  }

  render() {
    if (this.props.messages.length === 0) {
      return (null);
    }


    return (
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <div style={{overflowY: "auto", maxHeight: "160px"}}>
            {this.props.messages.slice(0).reverse().map((m, i) => 
              <Box key={i} mt={1}>
              <Alert severity="warning">{m}</Alert>
              </Box>
            )}
          </div>
        </Grid>
        <Grid item xs={3}>
          <Box m={1}>
          <Button size="small" variant="contained" color="secondary" onClick={this.clear}>
            Clear Messages
          </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }

}
