import React from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import IconSave from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";

import { instanceAPI } from "./Api";
import { addError, store, fetchInstances } from "./Store";
import { InstanceActionDialog } from "./Dialogs";
import CreateForm from "./CreateForm";
import MemoryWidget from "./MemoryWidget";

class CrownTableRow extends React.Component {
  constructor(props) {
    super(props);
    let instancetext = JSON.stringify(this.props.instance, null, 2);
    this.state = { open: false, dialog: false, instancetext: instancetext };
  }

  componentDidUpdate(prevProps) {
    // Update the instance text if we recieved a new one, but not if typing
    let instancetext = JSON.stringify(this.props.instance, null, 2);
    if (JSON.stringify(prevProps.instance) 
          !== JSON.stringify(this.props.instance)) {
      this.setState({instancetext: instancetext});
    }

  }

  setOpen = (open) => {
    this.setState({ open: open });
  };

  instancecontrol = (instance) => {
    return (e) => {
      this.setState({ dialog: true });
    };
  };

  onSave = (instance, type) => {
    return (e) => {
      let d = JSON.parse(this.state.instancetext);
      // Uses old ID
      d["type"] = type;

      instanceAPI
        .update(instance.id, d)
        .catch((e) =>
          store.dispatch(addError(`Error parsing json ${e.message}`))
        );
    };
  };

  dialogControl = (open) => {
    setTimeout(() => this.setState({ dialog: open }), 500);
    this.props.refresh(5000);
  };

  render() {
    let open = this.state.open;
    let row = this.props;

    return (
      <>
        <TableRow style={{display: row.hidden ? 'none': null}} key={row.internal_name}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row"><Tooltip title={row.memo}>
            <span>{row.label}</span>
          </Tooltip></TableCell>
          <TableCell align="right">{row.internal_name}</TableCell>
          <TableCell align="right">{row.owner}</TableCell>
          <TableCell align="right">
            <Tooltip title="Click to change instance state">
            <span>
            <Button
              color="primary"
              disabled={row.disabled}
              onClick={this.instancecontrol(this.props.instance)}
            >
              { row.status }
            </Button>
            </span>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={5} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h3">Details</Typography>
                <TextField
                  fullWidth={true}
                  label="URL"
                  margin={"normal"}
                  value={row.httpurl}
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  fullWidth={true}
                  multiline={true}
                  margin={"normal"}
                  rows={4}
                  label="Description"
                  value={row.memo}
                  inputProps={{ readOnly: true }}
                />
                <Alert severity="info">
                  Edit the options below in JSON. Changing some values will
                  cause inconsistencies. Avoid changing values like
                  "internal_value" which will cause the control system to fail.
                </Alert>
                <Paper elevation={2}>
                  <Box p={1}>
                    <Tooltip title="Merge edited JSON with database">
                    <span>
                    <Button color="primary" onClick={this.onSave(row, "save")}>
                      <IconSave />
                      Save
                    </Button>
                    </span>
                    </Tooltip>
                    <Tooltip title="Replace database JSON with editor">
                    <span>
                    <Button color="primary" disabled>
                      <IconSave />
                      Save Literal
                    </Button>
                    </span>
                    </Tooltip>
                    <CodeMirror
                      value={this.state.instancetext}
                      options={{
                        mode: "js",
                        theme: "material",
                        lineNumbers: true,
                      }}
                      onBeforeChange={(editor, data, value) => {
                        this.setState({ instancetext: value });
                      }}
                    />
                  </Box>
                </Paper>
              </Box>
            </Collapse>
            {this.state.dialog && (
              <InstanceActionDialog
                instance={this.props.instance}
                dialogControl={this.dialogControl}
              />
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }
}


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { instances: [], filter_self: false, filter_online: false, email: "" };
    this.timeoutid = null;
    this.fetchAll();
  }

  componentDidMount() {
    store.subscribe(() => {
      this.setState({ instances: store.getState().instances, email: store.getState().currentProfile.email});
    });
  }

  componentDidUpdate() {
      if (store.getState().errors.length >= 5 && this.id) {
        // Disable refresh if there is more than 5 errors
        store.dispatch(addError(`Refresh disabled, re-enable by clearing errors and hitting instance refresh button`));
        clearTimeout(this.id);
        this.id = null;
      }
  }

  fetchAllOnce = () => {
    store.dispatch(fetchInstances());
    // Start refresh if not already running
    if (!this.id) {
      this.id = setTimeout(this.fetchAll, 5000);
    }
  };

  fetchAll = (timeout=5000) => {
    this.fetchAllOnce();
    if (this.id) {
      clearTimeout(this.id);
      this.id = null;
    }
    this.id = setTimeout(this.fetchAll, timeout);
  };

  componentWillUnmount() {
    // Clear timeout for data fetching
    if (this.id) {
      clearTimeout(this.id);
      this.id = null;
    }
  }

  CB = (props) => {
    return (
      <Tooltip title={props.desc}>
      <FormControlLabel
        label={props.label}
        control={
          <Checkbox
            checked={this.state[props.field]}
            onChange={(event) => {
              let v = {};
              v[props.field] = event.target.checked;
              this.setState(v);
            }}
          />
        }
      />
      </Tooltip>
    );
  };

  render() {
    let rows = [];
    if (store.getState().instances) {
      rows = store.getState().instances.slice();
    }
    // Sort by ID
    rows.sort(r => r.id);
    if (this.state.filter_online) {
      rows = rows.filter(r => r.status == 'running');
    }
    if (this.state.filter_self) {
      rows = rows.filter(r => r.user_owner == this.state.email);
    }
    if (!rows) {
      rows = [];
    }

    return (
      <>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Typography variant="h2">Filters</Typography>
            <form>
              <this.CB label="Self" field="filter_self"
                desc="Only show which match your email." />
              <this.CB label="Running" field="filter_online"
                desc="Only show which running." />
            </form>
          </Grid>
          <Grid item xs={12} md={8}>
          <Box my={2}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="right">Label</TableCell>
                    <TableCell align="right">Internal Name</TableCell>
                    <TableCell align="right">Owner</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <CrownTableRow
                      key={row.internal_name}
                      instance={row}
                      refresh={this.fetchAll}
                      {...row}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Button onClick={this.fetchAllOnce} 
            variant="contained" style={{margin: '0.5rem 0'}}>
            Refresh
          </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <MemoryWidget/>
            <Typography variant="body2">
              Need help? <a style={{'color': 'white'}} href="https://clientspectrum.atlassian.net/wiki/spaces/AKB/pages/1754103809/Crown+System">Documentation on confluence.</a>
            </Typography>
          </Grid>
          </Grid>
        </Container>
        <CreateForm instances={rows} />
      </>
    );
  }
}
