import { ENDPOINT } from "./Config";

async function handleErrorJSONResponse(response, message) {
  let xtra = "";
  try {
    let j = await response.json()
    xtra = j.error;
  } catch (e) {
  }

  return new Error(`${message} (${response.status}) ${xtra}`)

  
}

export const instanceAPI = {
  fetchAll: async function () {
    const response = await fetch(ENDPOINT + "/instances", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ page: 1, perpage: 65536 }),
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error fetching instances');
    }

    return response.json();
  },
  control: async function (id, action) {
    const response = await fetch(ENDPOINT + "/control/" + id.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ action: action }),
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error controlling instances');
    }
    return response.json();
  },
  create: async function (data) {
    const response = await fetch(ENDPOINT + "/instance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error creating instance');
    }
    return response.json();
  },
  update: async function (id, data) {
    const response = await fetch(ENDPOINT + "/instance/" + id.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error updating instance');
    }
    return response.json();
  },
  islogged: async function () {
    const response = await fetch(ENDPOINT + "/loggedin", {
      credentials: "include",
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error creating instance');
    }
    let result = response.json();
    return result;
  },
  fetchImages: async function () {
    const response = await fetch(ENDPOINT + "/images", {
      credentials: "include",
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error requesting images');
    }
    let result = response.json();
    return result;
  },
};
export const userAPI = {
  fetchAll: async function () {
    const response = await fetch(ENDPOINT + "/user", {
      method: "GET",
      credentials: "include"
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error fetching users');
    }

    return response.json();
  },
  fetchCurrentProfile: async function () {
    const response = await fetch(ENDPOINT + "/userprofile", {
      method: "GET",
      credentials: "include"
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error fetching current profile');
    }

    return response.json();
  },
  update: async function (userid, role, add) {
    const response = await fetch(ENDPOINT + "/user/" 
        + userid.toString() + "/groups/" + role, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({change: add ? "add" : "remove"}),
    });

    if (!response.ok) {
      throw await handleErrorJSONResponse(response,
        'Error updating user groups');
    }

    return response.json();
  }
}
