import React from "react";
import "./App.css";
import "fontsource-roboto";
import { BrowserRouter as Router, Switch, Route/* ,Link*/ } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Home from "./Home";
import MessageCentre from './MessageCentre';

import { LOGINPAGE, LOGOUT } from "./Config";
import { instanceAPI } from "./Api";
import { addError, store } from "./Store";
import { AdminWindowDialog } from "./AdminWindow";
import { ErrorBoundary } from "./ErrorBoundary";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loggedin: false, errors: [], admindialog: false};
  }

  componentDidMount() {
    instanceAPI
      .islogged()
      .then((v) => {
        this.setState({ loggedin: v });
        if (!v.loggedin) {
          window.location = LOGINPAGE;
        }
      })
      .catch((e) => {
        store.dispatch(addError(e.message));
      });
    store.subscribe(() => this.setState({ errors: store.getState().errors }));
  }

  render() {
    return (
      <ErrorBoundary>
      <Router>
        <div>
          <Container maxWidth="md">
            <Grid
              container
              direction="row"
              alignItems="flex-end"
            >
              <Grid item xs={2}>
                <Typography variant="h1">Crown</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Adobe Campaign Classic modular instance management
                </Typography>
              </Grid>
              <Grid
                item xs={4}
              >
              <Grid container direction="row" 
              justify="flex-end" alignItems="center">
                {this.state.loggedin ? (
                  <Button
                    onClick={(e) => {
                      window.location = LOGOUT;
                    }}
                  >
                    Log out
                  </Button>
                ) : (
                  <Button variant="contained" color="primary"
                    onClick={(e) => {
                      window.location = LOGINPAGE;
                    }}
                  >
                    Log in
                  </Button>
                )}
                <Button variant="contained" color="secondary"
                  onClick={e => this.setState({admindialog: true})}>
                  Admin
                </Button>
                </Grid>
              </Grid>
            </Grid>
            {/*<nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
        </nav>*/}
          </Container>
          <Container maxWidth="lg">
            <MessageCentre messages={this.state.errors}/>
          </Container>
          {this.state.admindialog && <AdminWindowDialog dialogcontrol={b => this.setState({admindialog: b})}/>}

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      </ErrorBoundary>
    );
  }
}
