import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./Store";

import {CssBaseline} from "@material-ui/core"
import {createMuiTheme} from "@material-ui/core/styles"
import { ThemeProvider } from '@material-ui/core/styles';


const theme = createMuiTheme({
    typography: {
      h1: {
        fontSize: "2rem"
      },
      h2: {
        fontSize: "1.5rem"
      },
      h3: {
        fontSize: "1.17rem"
      },
      h4: {
        fontSize: "1rem"
      },
      h5: {
        fontSize: "0.83rem"
      },
      h6: {
        fontSize: "0.67rem"
      }
    },
    palette: {
      primary: {
        light: '##b6ffff',
        main: '#81d4fa',
        dark: '#4ba3c7',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },

      type: 'dark',
      background: {
      }
    },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Provider store={store}>
    <CssBaseline />
    <App />
  </Provider>,
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
